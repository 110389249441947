import Turbo from "@hotwired/turbo"
import "controllers"

//require("rails-ujs").start()
//require("@rails/activestorage").start()
//require("channels")
require("jquery")

import 'lazyload';

let images = document.querySelectorAll(".lazyload");
lazyload(images);

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import '../javascripts/globals';
import '../javascripts/messages.js';
import '../javascripts/materialize.js';
import '../javascripts/teaser_slider';
import '../javascripts/references_slider';
import '../javascripts/cookieconsent';
import './actiontext';

import "/home/deploy/servers/emvellon/production/shared/bundle/ruby/2.6.0/gems/picture_tag-2.0.0/lib/assets/javascripts/picture_tag"
